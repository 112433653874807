import React from 'react';
import {connect, useDispatch} from "react-redux"
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/UkResidents/NonHCP/UkNonHCPFooter';
import Header from '../../../components/UkResidents/NonHCP/header';
import Alkindi from "../../../images/alkindi_cmyk.png"
import Pdf from "../../../images/pdf_icon.png"
import UkImg from "../../../images/uk_flag.png"
import German from "../../../images/german.png"
import Czech from "../../../images/czech-flag.png" 
import { Icon } from 'react-icons-kit'
import {ic_picture_as_pdf} from 'react-icons-kit/md/ic_picture_as_pdf'
import {link} from 'react-icons-kit/icomoon/link'
import { Link, navigate, graphql } from "gatsby";
import PdfFile from "../../../images/uk_prescribing-information_version-4-pp.pdf"
import ScrollAnimation from 'react-animate-on-scroll';
import {showUkResidentsNonHcpDisclaimerPopup} from "../../../actions/ukResidents/nonHcp/"
import {toggleUkResidentsNonHcpDisclaimerPopup} from "../../../controllers/ukResidents/nonHcp/disclaimer"
import {getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigs, getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigsEfmody} from "../../../util/configs"
import {capitalizeFirstLetter, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions} from "../../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultUkResidentsNonHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import queryString from "query-string"
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryCountryInformationTemplateUkNonHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCountryInformationTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                bannerImage {
                    file {
                        url
                    }
                }
                bodyText {
                    json
                }
                spaceId
                slug
                title
                parentMenu {
                    country_information_template {
                      parentMenu {
                        title
                        slug
                      }
                    }
                }
                video_pdf_template {
                    title
                    flag {
                      file {
                        url
                      }
                    }
                    slug
                }
        } 
    }
`

const CountryInformationTemplatePageUkNonHcp = (props) => {
    const dispatch = useDispatch()

    const {bannerImage, title, spaceId, bodyText, parentMenu, video_pdf_template} = props.data.contentfulCountryInformationTemplate;

    const togglePopup = (path, patients) => {
        let okCallback = async () => await navigate(path)
        let closeCallback = async () => {}
        let popupConfig = {} 
        switch(patients){
            case "information-for-uk-patients":
                popupConfig = getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigs(okCallback, closeCallback);
                break;
            case "Information for UK Patients on Efmody®":
                popupConfig = getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigsEfmody(okCallback, closeCallback);
                break;
            default:
                navigate(path);
                return;
        }
        dispatch(showUkResidentsNonHcpDisclaimerPopup(popupConfig))
        localStorage.setItem("ukresidents-nonhcp-info-for-uk-patients", JSON.stringify({ disclaimerShown: true }));

    }

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultUkResidentsNonHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showUkResidentsNonHcpDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
        
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);



    return ( 
       <TemplateWrapper>
            <div style={{background: '#dedede'}}>
              <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | UK Residents | Non-HCP`} />
        
      
            <div className={styles.banner} style={{background: `url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
            <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                <h1 className="">
                                   {title}</h1>
                            
                                <ol className="custom_breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/UkResidents/Non-HCP/home'}>Home</Link></li>
                                {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.country_information_template) && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu.country_information_template[0].parentMenu.title}    
                                        </a></li>}
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {title}
                                    </li>
                                </ol>
                            
                               </div>
                           
                               </div>
                        </div>
            </div>

            <div className={styles.content}>
               <div className="container">
             <div className="row">
             {video_pdf_template?.map((item, index) => {
                      return (
                        <div key={index} className="col-12 col-sm-12 col-md-4">
                        <ScrollAnimation animateIn="fadeIn">
                        <div className={styles.content_section}>
                            <div className={styles.flag}>
                            <img src={!isNullUndefined(item.flag) && item.flag.file.url}/>
                            </div>
                            <h3>{item.title}</h3>
                        <a onClick={() => togglePopup(`/UkResidents/Non-HCP/${item.slug}`, item.slug)}><button className="custom_btn dark bordered_btn">Read more</button></a> 
                    </div>
                    </ScrollAnimation>
                    </div>
                        )
                    })}

                   
               

                 
               
               
                </div>
               </div>
            </div>

            <div className={styles.flags_section}>
                    <div className="container ">
                  <div className="row">
                   
                  <ScrollAnimation animateIn="fadeIn">
                    {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                   </ScrollAnimation>
                    
                  </div>
                    </div>
            </div>
            <Footer/>
        </div>
       </TemplateWrapper>
     );
}

export default CountryInformationTemplatePageUkNonHcp;